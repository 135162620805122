<div class="pt-5"></div>

<div class="main-container support">
  <h1 class="text-center text-bold mb-5">{{ 'support.title' | translate }}</h1>

  <div class="container">
    <div class="row ">
      <div class="col-6 col-md-2 d-flex flex-column justify-content-between">
        <img class="p-4" style="max-width: 100%" src="assets/img/shopping-cart.svg" alt="">
        <img class="p-4" style="max-width: 100%" src="assets/img/headbook.svg" alt="">
        <img class="p-4" style="max-width: 100%" src="assets/img/headsearch.svg" alt="">
        <img class="p-4" style="max-width: 100%" src="assets/img/graph.svg" alt="">
      </div>
      <div class="col-6 col-md-1 vertical-arrow">
      </div>
      <div class="col-12 col-md-9" >
        <div class="service" *ngFor="let service of 'support.services' | translate">
          <h3 class="text-bold">{{service.title}}</h3>
          <p *ngFor="let text of service.texts">
            {{text.p}}
          </p>
        </div>

      </div>
    </div>
  </div>
</div>
