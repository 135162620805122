

<div class="main-container hero1 parallax">
  <div class="overlay d-flex justify-content-center align-content-end">
    <div class="row w-100 h-100  d-flex justify-content-center align-content-end">
      <div class="col-12 col-md-8 col-lg-6 pb-5">
        <h1 class="text-center text-white">{{'about-us.title' | translate}}</h1>
        <p class="text-center text-white">
          {{ 'about-us.subtitle' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>

<div class="main-container">
  <div class="container">
    <div class="row pt-5 pb-5">
      <div class="col-12 col-md-6 p-4">
        <h2 class="mb-4">{{'about-us.mission.title' | translate}}</h2>
        <p>
          {{'about-us.mission.p' | translate}}
        </p>
      </div>
      <div class="col-12 col-md-6 p-4">
        <h2 class="mb-4">{{'about-us.vision.title' | translate}}</h2>
        <p>
          {{'about-us.vision.text' | translate}}
        </p>
      </div>
    </div>
  </div>

</div>
