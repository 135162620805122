import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LanguageService} from '../../services/language.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public languageService: LanguageService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
  }

  changeLanguage(language: string) {
    this.translate.use(language);
    this.languageService.setLanguage(language);
  }

  showLinks() {
    const navLinks = document.querySelector('.nav-links');
    navLinks.classList.toggle('active');
  }

}
