<header>
  <div class=" pl-2 pr-2 pl-md-5 pr-md-5 d-flex justify-content-between h-100">
    <div class="d-flex align-items-center h-100">
      <a routerLink="">
        <img style="z-index: 9999" src="assets/img/logotipo.svg" alt="Cloud to Business">
      </a>

    </div>

    <div class="hamburger pr-3">
      <button (click)="showLinks()" id="hamburger" type="button">
        <mat-icon>
          menu
        </mat-icon>
      </button>

    </div>


    <ul class="nav-links " (click)="showLinks()">
      <li *ngFor="let link of 'navigation-links'| translate">
        <a [routerLink]="link.routerLink" routerLinkActive="active" >{{ link['name'] }}</a>
      </li>
      <li>
        <span [ngClass]="{'active': languageService.getLanguage() === 'en'}" (click)="changeLanguage('en')">EN</span>
        |
        <span [ngClass]="{'active': languageService.getLanguage() === 'es'}" (click)="changeLanguage('es')">ES</span>
      </li>
    </ul>
  </div>
</header>
