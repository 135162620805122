import {Component, OnInit} from '@angular/core';
import {AvailableLanguages, LanguageService} from './services/language.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'cloud-business';
  loaded = false;
  constructor(
    private languageService: LanguageService,
    private translate: TranslateService
  ) {
    if (this.languageService.getLanguage()) {
      translate.use(this.languageService.getLanguage());
    } else {
      this.languageService.setLanguage('en');
    }
  }
  ngOnInit() {
    this.loaded = true;
  }
}
