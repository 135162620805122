import {AfterContentChecked, AfterViewChecked, AfterViewInit, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
declare var stickyMenu;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterViewInit {
  contactForm: FormGroup;
  tabs = {
    1: true,
    2: false,
    3: false
  };
  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.reloadScript();
  }

  reloadScript() {
    const top = document.getElementById('sticky').offsetTop;
    if (sessionStorage.getItem('top') === null) {
      sessionStorage.setItem('top', String(top));
    }

    stickyMenu(sessionStorage.getItem('top'), document.getElementById('sticky'));
  }

  submit() {
    console.log(this.contactForm.status);
    console.log(this.contactForm.value);
  }

  navClick(navId: string) {
    const services = document.querySelector('#activeServices');
    const support = document.querySelector('#activeSupport');
    const career = document.querySelector('#activeCareer');
    services.classList.remove('active');
    support.classList.remove('active');
    career.classList.remove('active');
    const current = document.querySelector(`#${navId}`);
    current.classList.add('active');
  }

  tabsChange(num: number) {
    for ( let i = 1; i <= 3; i++) {
      this.tabs[i] = i === num;
    }
  }

}
