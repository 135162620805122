<div class="pt-5"></div>
<div class="main-container  products-services strategic">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <h1
          class="text-center mb-2">{{'strategic-alliances.title' | translate}}</h1>
        <p class="text-center">
          {{'strategic-alliances.text' | translate}}
        </p>
      </div>
    </div>

  </div>

</div>

<div class="row m-0 pl-3 pr-3 mb-5 clients-images">
  <div class="col-12 col-md-4 d-flex justify-content-center" *ngFor="let client of 'strategic-alliances.clients' | translate">
    <img [src]="client.src" alt="img">
  </div>
</div>
