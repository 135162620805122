<footer>

  <div class="row">
    <div
      class="col-12 col-sm-12 col-md-6 col-lg-6">
      <img src="assets/img/logotipo.svg" alt="">
      <p class="gray mt-2 w-50"
         style="font-size: 14px">
        Todos los derechos reservados de
        CloudtoBusiness.
      </p>
    </div>

    <div
      class="col-12 col-sm-12 col-md-6 col-lg-6">
      <ul class="footer-hor flex-column flex-lg-row" >
        <li *ngFor="let navLink of 'footer.nav-links' | translate"><a [routerLink]="navLink.routerLink">{{navLink.name}}</a></li>
      </ul>
    </div>
  </div>

</footer>
