<div class="pt-5"></div>
<div class="main-container  products-services">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-md-8">
        <h1
          class="text-center mb-2">{{'products-services.title' | translate}}</h1>
        <p class="text-center">
          {{'products-services.p' | translate}}
        </p>
      </div>
    </div>

  </div>

</div>

<div class="pl-5 pr-5">
  <div class="row m-0">
    <div class="col-12 col-md-7 d-flex justify-content-center p-5">
      <img src="assets/img/lap%201.png"
           class="mt-5 w-100 mw-100" alt="laptop1">
    </div>
    <div class="col-12 col-md-5">
      <h3 class="m-0">{{'products-services.laptop-area.title' | translate}}</h3>
      <ng-container *ngFor="let p of 'products-services.laptop-area.ps' | translate">
        <p class="mt-5">{{p.p}}</p>
      </ng-container>
    </div>

  </div>
</div>


<!--<div class="main-container infra">
  <div class="container">
    <h2
      class="text-center">{{'products-services.infra.title' | translate}}</h2>
    <div class="row justify-content-center">
      <div class="col-12 col-md-6 ">
        <p
          class="text-center"> {{'products-services.infra.subtitle' | translate}} </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-3" *ngFor="let item of 'products-services.infra.list' | translate">
        <p class="text-center text-bold"> {{item.name}} </p>
      </div>
      <div class="col-12 col-md-3  mb-3 mb-md-0 d-flex justify-content-center">
        <img src="assets/img/infra/hp.png" alt="">
      </div>
      <div class="col-12 col-md-3  mb-3 mb-md-0 d-flex justify-content-center">
        <img src="assets/img/infra/ca.png" alt="">
      </div>
      <div class="col-12 col-md-3  mb-3 mb-md-0 d-flex justify-content-center">
        <img src="assets/img/infra/emc.png" alt="">
      </div>
      <div class="col-12 col-md-3  mb-3 mb-md-0 d-flex justify-content-center">
        <img src="assets/img/infra/netapp.png" alt="">
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 col-md-4" *ngFor="let item of 'products-services.infra.list2' | translate">
        <p class="text-center text-bold"> {{item.name}} </p>
      </div>
      <div class="col-12 col-md-6 mb-3 mb-md-0 d-flex justify-content-center">
        <img src="assets/img/infra/redhat.png" style="height: 1.8rem" alt="">
      </div>
      <div class="col-12 col-md-6 mb-3 mb-md-0 d-flex justify-content-center">
        <img src="assets/img/infra/vmware.png" alt="">
      </div>
    </div>

    <div class="row admin ">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6">
            <h1 class="text-bold">{{'products-services.admin.title' | translate}}</h1>
            <p>{{'products-services.admin.p' | translate}}</p>
          </div>
          <div class="col-12 col-md-6">
            <p class="text-bold">{{(activeString +  '.title') | translate}}</p>
            <ul >
              <li *ngFor="let item of (activeString + '.list') | translate">
                {{item.name}}
              </li>
            </ul>
            <div class="row justify-content-center">
              <div class="col-6 ">
                <div class="row">
                  <div class="col-4">
                    <div class="indicator  "
                                          (click)="changeAdmon(1)"
                                          [ngClass]="{ 'active': tabs['1']}"></div>
                  </div>
                  <div class="col-4"><div class="indicator "
                                          (click)="changeAdmon(2)"
                                          [ngClass]="{ 'active': tabs['2']}"></div></div>
                  <div class="col-4"><div class="indicator "
                                          (click)="changeAdmon(3)"
                                          [ngClass]="{ 'active': tabs['3']}"></div></div>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>

  </div>
</div>-->

<div class="main-container dev">
  <div class="container">
    <div class="row mt-5 justify-content-center align-items-stretch">
      <div class="col-12 col-md-6 d-flex flex-column mt-5" *ngFor="let service of 'products-services.services-items'| translate">
        <img [src]="service.src" alt="src">
        <h4 class="mt-4 text-center">{{service.title}}</h4>
        <p class="mt-3 text-center">{{service.p}}</p>
      </div>
    </div>
  </div>
</div>
