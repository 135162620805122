<div class="main-container contact">
  <h1
    class="text-center text-bold mb-5">{{'contact.title'|translate}}</h1>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class=" map"></div>
      </div>
      <div
        class="col-12 col-md-6 d-flex pt-5  flex-column  ">
        <p
          class="text-bold ">{{'contact.location' | translate}}</p>
        <p class="">
          26022 Budde Rd, <br> The woodlands, Tx,
          <br> Postal Code 77382
        </p>

        <p
          class="text-bold ">{{'contact.email' | translate}}</p>
        <p class="tagmail"><a class="mail"
                              href="mailto:contacto@cloudtobusiness.com">contacto@cloudtobusiness.com</a>
        </p>
      </div>
    </div>

  </div>
</div>


