import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {ProductsAndServicesComponent} from './pages/products-and-services/products-and-services.component';
import {AboutUsComponent} from './pages/about-us/about-us.component';
import {ContactComponent} from './pages/contact/contact.component';
import {StrategicAlliancesComponent} from './pages/strategic-alliances/strategic-alliances.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'products-and-services', component: ProductsAndServicesComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'strategic-alliances', component: StrategicAlliancesComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
