import {Injectable} from '@angular/core';

export enum AvailableLanguages {
  'en',
  'es'
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor() {
  }

  setLanguage(language: string) {
    localStorage.setItem('language', language);
  }

  getLanguage(): string | null {
    return localStorage.getItem('language');
  }
}
