
<div class="pt-5"></div>
<div class="main-container hero1 parallax">
  <div class="overlay">
    <div class="container h-100">
      <div class="row align-items-center h-100 ">
        <div
          class="col-12 col-md-12 col-lg-12 col-xl-10">
          <h1>{{ 'home.hero1.title' | translate }}</h1>
          <!--<a class="btn btn-success mt-3"
             routerLink="/contact">
            {{'home.buttons.btnContact' | translate}}
          </a>-->
        </div>
      </div>
    </div>
  </div>
</div>
<!--<div class="row products-nav justify-content-center" id="sticky">
  <div class="col-3 col-md-3 col-lg-3"
       *ngFor="let product of 'home.product-nav' | translate ">
    <a pageScroll [href]="product.dir"
       (click)="navClick(product.id)"
       class="product-group">
      <img [src]="product.src" alt="services">
      <label>{{ product['name'] }}</label>
      <div [id]="product.id"></div>
    </a>

  </div>
</div>
<div class="main-container section products" >
  <div class="container">


    <div class="row product-section pb-5"
         id="services">
      <div class="col-12 col-md-4">
        <h1
          class="title-product">{{'home.services.title' | translate}}</h1>
        <p
          *ngFor="let p of 'home.services.pp' | translate "
          [innerHTML]="p.p"></p>
      </div>
      <div class="col-12 col-md-8  mt-5 d-flex">
        <img src="assets/img/lap%201.png"
             class="mt-5" alt="laptop1">
      </div>
    </div>

  </div>
</div>
<div class="main-container">
  <div class="container">
    <div class="row product-section">
      <div class="col-12">
        <h1 class="title-product text-center mb-5"
            id="support">
          {{'home.support.title' | translate}}
        </h1>
        &lt;!&ndash;        <div class="arrow1 mb-3"></div>&ndash;&gt;
      </div>
    </div>
  </div>
  <div class="line-time w-100">
    <div class="container">
      <div class="row">
        <div
          *ngFor="let circle of 'home.support.circles-text' | translate; index as i"
          class="col col-12 col-sm-6 col-md-3 col-lg-3">
          <div class="circle-green">
            <label>{{i + 1}}</label>
          </div>
          <p>{{ circle.name }}</p>
        </div>
      </div>
    </div>
  </div>

  <div
    class="d-flex align-items-center justify-content-center mt-5 mb-5">
    <a routerLink="/support"
       class="btn btn-outline-success ">{{'home.buttons.btnKnowMore' | translate}}</a>
  </div>

</div>

<div class="position-relative">
  <div class="main-container section career">
    <div class="container">
      <div class="row  pt-md-5" style="z-index: 2">
        <div
          class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ml-auto mr-0 text-right">
          <h1 class="title-product text-right"
              id="career">
            {{'home.career.title' | translate}}
          </h1>
          <p class="text-right mb-0">
            {{ 'home.career.p' | translate }}
          </p>
          <a
            href="mailto:carreras@cloudtobusiness.com.mx"
            class="mail text-right">carreras@cloudtobusiness.com.mx</a>
        </div>
      </div>
    </div>


  </div>





</div>

<div class="main-container section clients">
  <div class="container pt-5 pb-5">
    <h1
      class="text-center mt-5 mb-5">{{'home.clients.title' | translate}}</h1>
    <div class="clients-pag">
      <div *ngIf="tabs['1']" class="clients-img">
        <div class="row justify-content-center mb-5">
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/epson.png" alt="">
          </div>
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/kraft.png" alt="">
          </div>
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/camery.png" alt="">
          </div>
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/kelloggs.png" alt="">
          </div>


          <div class=" col-4 col-md-4 p-4">
            <img class="opacity-1" src="assets/img/clients/pepsico.png" alt="">
          </div>
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/google.png" alt="">
          </div>
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/microsoft.png" alt="">
          </div>
          <div class=" col-4 col-md-2 p-4 d-flex justify-content-center">
            <img class="opacity-1" src="assets/img/clients/sap.png" alt="">
          </div>
        </div>
      </div>
      <div *ngIf="tabs['2']" class="clients-img">
        <div class="row">
          <div class=" col-4 col-md-2 p-4">
            <img src="" alt="">
          </div>
        </div>
        2
      </div>
      <div *ngIf="tabs['3']" class="clients-img">
        <div class="row">
          <div class=" col-4 col-md-2 p-4">
            <img src="" alt="">
          </div>
        </div>
        3
      </div>
    </div>
  </div>

</div>-->


