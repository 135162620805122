import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-and-services',
  templateUrl: './products-and-services.component.html',
  styleUrls: ['./products-and-services.component.scss']
})
export class ProductsAndServicesComponent implements OnInit {
  activeString  = 'products-services.admin.office.1';
  activeStringList = 'products-services.admin.office.1.title.list';
  tabs = {
    1: true,
    2: false,
    3: false
  };

  constructor() { }

  ngOnInit(): void {
  }

  changeAdmon(num: number) {
    this.activeString = `products-services.admin.office.${num}`;
    // this.activeString = `products-services.admin.office.1.list`;
    for (let i = 1; i <= 3; i++) {
      this.tabs[i] = i === num;
    }
  }

}
